var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('b-row',[_c('div',{staticClass:"border-3 border-right-info  p-0 ",staticStyle:{"width":"14%"}},[_c('div',{staticClass:"navContainer"},[_c('b-nav-item',{staticClass:"text-left",attrs:{"to":{
              name: 'debt-solution-courts',
            },"exact-active-class":"sub-tab-nav","link-classes":[ _vm.getRoute('debt-solution-courts')?'link-active':'link-inactive',
              'sub-tab-nav',
              'px-3',
              'text-truncate',
              _vm.bgTabsNavs ]}},[_c('span',{staticClass:"menu-title"},[_vm._v("COURTS")])]),_c('b-nav-item',{staticClass:"text-left",attrs:{"to":{ name: 'debt-solution-attorney' },"exact-active-class":"sub-tab-nav","link-classes":[ _vm.getRoute('debt-solution-attorney')?'link-active':'link-inactive',
              'sub-tab-nav',
              'px-3',
              'text-truncate',
              _vm.bgTabsNavs ]}},[_c('span',{staticClass:"menu-title "},[_vm._v("ATTORNEYS")])])],1)]),_c('div',{staticClass:"col"},[_c('b-card',{attrs:{"no-body":""}},[_c('router-view',{key:_vm.$route.name,ref:"routerView"})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }