import { render, staticRenderFns } from "./MainCourts.vue?vue&type=template&id=79e52e00&scoped=true"
import script from "./MainCourts.vue?vue&type=script&lang=js"
export * from "./MainCourts.vue?vue&type=script&lang=js"
import style0 from "./MainCourts.vue?vue&type=style&index=0&id=79e52e00&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79e52e00",
  null
  
)

export default component.exports