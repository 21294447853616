<template>
    <div class="p-2">
      <b-row> 
          <div  class="border-3 border-right-info  p-0 " style="width:14%;">
          <div class="navContainer" > 
              <b-nav-item
                :to="{
                  name: 'debt-solution-courts',
                }"
                exact-active-class="sub-tab-nav"  
                :link-classes="[ getRoute('debt-solution-courts')?'link-active':'link-inactive',
                  'sub-tab-nav',
                  'px-3',
                  'text-truncate',
                  bgTabsNavs,
                ]"
                class="text-left"
              > 
                <span class="menu-title">COURTS</span>
              </b-nav-item> 
              <b-nav-item
                :to="{ name: 'debt-solution-attorney' }"
                exact-active-class="sub-tab-nav"  
                :link-classes="[ getRoute('debt-solution-attorney')?'link-active':'link-inactive',
                  'sub-tab-nav',
                  'px-3',
                  'text-truncate',
                  bgTabsNavs,
                ]"
                class="text-left"
              > 
                <span class="menu-title ">ATTORNEYS</span>
              </b-nav-item>
          </div>
        </div>  
        <div class="col">
          <b-card
            no-body 
          >
            <router-view ref="routerView" :key="$route.name" />
          </b-card>
        </div> 
      </b-row>
  
      
    </div>
  </template>
  <script>
  export default { 
    methods:{
      getRoute(route){
        return route == this.$route.name;
      }, 
    }
  };
  </script>
  <style lang="scss" scoped>
  
  .navContainer{
    list-style:none;
  
  }
  
  .link-inactive:hover {
    transition: 0.25s ease;
    & a {
    margin-left: 4px; 
    }
    background: #FFFFFF !important;
    color: #726D84; 
  }
  
  .link-inactive{
      padding: 10px 15px !important; 
      background: #FFFFFF;
      color: #726D84; 
      border-radius: 6px 0px 0px 6px; 
    }
  
  
  
  .link-active{
    padding: 10px 15px !important;
    background: #8F5FE8;
    color: #F8F6FE;
    border-radius: 6px 0px 0px 6px; 
  }
  
  
  .dark-layout{
    & .link-inactive{
      padding: 10px 15px !important;
      background: #17171A;
      color: #A2A2A3; 
      border-radius: 6px 0px 0px 6px; 
    }
    & .link-inactive:hover {
      transition: 0.25s ease;
      margin-left: 4px;
      background: #17171A !important;
      color: #A2A2A3; 
    }
    .link-active{
      padding: 10px 15px !important;
      background: #8F5FE8;
      color: #F8F6FE;
      border-radius: 6px 0px 0px 6px;  
    }
  }
  
  
  
  </style>
  